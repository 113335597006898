import freeTagImg from '~/assets/img/free-tag.svg';

export default function Header({ children, freeTag = false, primary = false }) {
  const HTag = ({ children, primary = false }) => {
    const className = 'header header__primary header--extra-bold';

    return primary ? (
      <h1 className={className}>{children}</h1>
    ) : (
      <h2 className={className}>{children}</h2>
    );
  };

  return (
    <header>
      <HTag primary={primary}>
        {children}
        {freeTag && <img className="header__pic" src={freeTagImg} alt="free" />}
      </HTag>
    </header>
  );
}

import Link from 'next/link';
import Layout from '~/layouts/default';
import Seo from '~/components/seo';
import Main from '~/components/main';
import Advantages from '~/components/advantages';
import Advantage from '~/components/advantage';
import Packagist from '~/components/packagist';
import CommonAdvantages from '~/components/advantages/commonAdvantages';
import CtaClick from '~/components/ctaClick';
import {
  getDocsUrl,
  getRegisterUrl
} from '~/helpers/url';

export default function Page() {
  return (
    <>
      <Layout>
        <Seo
          title="Private Packages"
          description="Add and manage private PHP Composer dependencies in cloud or behind your firewall"
        />
        <Main>
          <Main.Header title="Private PHP Package Repository Manager">
            Add and manage private PHP Composer dependencies in cloud or behind
            your firewall. Dedicated integration with GitHub, GitLab and
            Bitbucket.
          </Main.Header>
          <Main.Content>
            <p className="text text__primary">Sign up for free with...</p>
            <CtaClick cta={['sign-up-private-packages-github']} generalCta={['sign-up-private-packages']}>
              <a href={getRegisterUrl('github')} className="btn btn-primary btn-icon _github">
                GitHub
              </a>
            </CtaClick>
            <CtaClick cta={['sign-up-private-packages-gitlab']} generalCta={['sign-up-private-packages']}>
              <a href={getRegisterUrl('gitlab')} className="btn btn-primary btn-icon _gitlab">
                Gitlab
              </a>
            </CtaClick>
            <CtaClick cta={['sign-up-private-packages-bitbucket']} generalCta={['sign-up-private-packages']}>
              <a href={getRegisterUrl('bitbucket')} className="btn btn-primary btn-icon _bitbucket">
                Bitbucket
              </a>
            </CtaClick>
            <p className="text text__primary">or</p>
            <CtaClick cta={['install-self-hosted-private-packages']}>
              <a href={getDocsUrl('standalone')} className="btn btn-secondary btn-icon _download">
                Install Self-hosted
              </a>
            </CtaClick>
          </Main.Content>
        </Main>
        <Advantages>
          <CommonAdvantages />
          <Advantage>
            <Advantage.Header>
              Easy<br />to use
            </Advantage.Header>
            <Advantage.Content>
              Add your private package{' '}
              <a
                href={getDocsUrl('tutorials/how-to-distribute-private-package-from-github')}
                className="link"
              >
                in 2 clicks
              </a>.
            </Advantage.Content>
          </Advantage>
          <Advantage>
            <Advantage.Header>
              REST API
            </Advantage.Header>
            <Advantage.Content>
              Manage your packages using{' '}
              <a
                href={getDocsUrl('api')}
                className="link"
              >
                REST API
              </a>.
            </Advantage.Content>
          </Advantage>
        </Advantages>
        <Packagist header="Proxy Packagist">
          <p className="text text--light">
            Speed up downloading Composer dependencies thanks to our global CDN.
            <br />
            Supports all packages from packagist.org.
          </p>
          <Link href="/proxy">
            <a className="btn btn-primary free-tag">
              Get it now
            </a>
          </Link>
        </Packagist>
      </Layout>
    </>
  );
}

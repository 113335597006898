import Header from '~/components/header';

const Main = ({ className, children }) => {
  return (
    <section className={`main ${className || ''}`}>
      <div className="container">
        {children}
      </div>
    </section>
  );
};

const MainHeader = ({ title, children }) => {
  return (
    <article className="article">
      <Header primary freeTag>{title}</Header>
      <p className="text text__primary">
        {children}
      </p>
    </article>
  );
};

const MainContent = ({ children }) => {
  return (
    <div className="btn-container">
      {children}
    </div>
  );
};

Main.Header = MainHeader;
Main.Content = MainContent;

export default Main;

import Header from '~/components/header';

export default function Packagist({ header, children }) {
  return (
    <section className="packagist">
      <div className="container">
        <article className="article">
          <Header freeTag>{header}</Header>
          {children}
        </article>
      </div>
    </section>
  );
}


const Advantage = ({ children }) => {
  return (
    <article className="article">
      {children}
    </article>
  );
};

const AdvantageHeader = ({ children }) => {
  return (
    <header>
      <h3 className="header header__primary">
        {children}
      </h3>
    </header>
  );
};

const AdvantageContent = ({ children }) => {
  return (
    <p className="text text--light">
      {children}
    </p>
  );
};

Advantage.Header = AdvantageHeader;
Advantage.Content = AdvantageContent;

export default Advantage;

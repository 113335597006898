export default function Advantages({ children }) {
  return (
    <section className="advantages">
      <div className="container">
        <div className="advantages_boxes">
          {children}
        </div>
      </div>
    </section>
  );
}
